<template>
  <v-btn
    text
    :color="$vuetify.breakpoint.mobile ? 'white' : 'primary lighten-1'"
    class="font-weight-bold text-none text-body-2 pa-4 mt-2 text-decoration-underline d-flex text-wrap break-word"
    target="_blank"
    @click="redirectToMarketplace"
  >
    <span style="white-space: normal"> {{ $ml.get("installApp") }} </span>
  </v-btn>
</template>

<script>
import { openInNewTab } from "@/helpers/utils";
export default {
  name: "InstallAppButton",

  methods: {
    redirectToMarketplace() {
      const link = `https://workspace.google.com/marketplace/app/conecta_suite/${process.env.VUE_APP_MARKETPLACE_APP_ID}`;
      openInNewTab(link);
    },
  },
};
</script>
