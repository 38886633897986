<template>
  <v-card tile flat color="transparent" class="ma-auto" max-width="400">
    <v-card-actions class="px-4 justify-center">
      <LoginWithGoogle />
    </v-card-actions>

    <v-card-actions class="px-4 justify-center">
      <LoginWithMicrosoft />
    </v-card-actions>

    <!-- BOTÃO DE EXPERIMENTE GRÁTIS -->
    <v-card-actions class="px-4 justify-center">
      <TryForFreeButton />
    </v-card-actions>

    <!-- BOTÃO DE ESCOPOS -->
    <v-card-actions class="px-4 justify-center">
      <AboutScopesButton />
    </v-card-actions>

    <!-- INSTALL APP BUTTON -->
    <v-card-actions class="px-4 justify-center">
      <InstallAppButton />
    </v-card-actions>
  </v-card>
</template>
<script>
import AboutScopesButton from "@/components/forms/buttons/AboutScopesButton";
import InstallAppButton from "@/components/forms/buttons/InstallAppButton";
import LoginWithGoogle from "@/components/forms/buttons/LoginWithGoogle";
import LoginWithMicrosoft from "@/components/forms/buttons/LoginWithMicrosoft";
import TryForFreeButton from "@/components/forms/buttons/TryForFreeButton";

export default {
  name: "LoginActions",

  components: {
    AboutScopesButton,
    InstallAppButton,
    LoginWithGoogle,
    TryForFreeButton,
    LoginWithMicrosoft,
  },
};
</script>
